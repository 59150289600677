var userAppService = new Vue({

    methods: {


        /**
         * Get user data
         *
         * @param id
         * @param successCallback
         * @param errorCallback
         */
        get: function(id, successCallback, errorCallback) {
            this.$http.post(servicesConfig.apiHost + '/user/get', {id}, headersConfig).then(response => {
                successCallback(response.data.params);
            }, error => {
                errorCallback(error.data);
            });
        }


    }

});
